IHI.Pages.dashboardMyApplications = function (options) {

    $('#my-applications-new').on('click', function (e) {
        e.preventDefault();
        IHI.redirect('/Application');
    });

    $('.change-photo').on('click', function (e) {
        e.preventDefault();

        var $container = $(this).next('.change-photo-container');
        Popup.show($container.children().get(0), {
            styles: Popup.styles.hybrid
        }, {
            onX: function () {
                IHI.redirect('/Dashboard/MyApplications');
            },
            afterHide: function() {
                IHI.redirect('/Dashboard/MyApplications');
            }
        });
    });
    
    var imageUrl = $('.main-cropper').attr('url');
    var config = {
        viewport: { width: 300, height: 300 },
        boundary: { width: 500, height: 400 },
        //showZoomer: true,
        format: 'png' //'jpeg'|'png'|'webp'
    };
    if (imageUrl !== '') {
        config.url = imageUrl;
    }
    var basic = $('.main-cropper').croppie(config);

    /*var basic = $('.main-cropper').croppie
        ({
            viewport: { width: 300, height: 300 },
            boundary: { width: 500, height: 400 },
            //showZoomer: true,
            format: 'png' //'jpeg'|'png'|'webp'
        });*/


    //Reading the contents of the specified Blob or File
    function readFile(input) {
        if (input.files && input.files[0]) {
            var reader = new FileReader();

            reader.onload = function (e) {
                $('.main-cropper').croppie('bind', {
                    url: e.target.result
                });
            }
            reader.readAsDataURL(input.files[0]);
        }
    }

    $('#file').on('change', function () { readFile(this); });

    $('#btnupload').click(function () {
        var result = basic.croppie('result', 'base64');
        basic.croppie('result', 'base64').then(function (base64) {
            var newPlayerId = $('#playerId').val();
            $.post('/Dashboard/FileUpload', {
                image: base64,
                newPlayerId: newPlayerId
            }, function (response) {
                if (!response.success) {
                    alert("Upload successful");
                }
                else {
                    IHI.redirect('/Dashboard/MyApplications');
                }
            }, 'json');
        });
    });
}