IHI.Pages.dashboardTeams = function (options) {

    var $addTeam = $('#teams-new');
    var $tabs = $('#category-tabs a');
    var $content = $('#category-content table');

    $tabs.on('click', function (e) {
        e.preventDefault();
        var $tab = $(this),
            index = $tab.index();

        $tab.siblings().removeClass('on');
        $tab.addClass('on');

        var $table = $content.eq(index);
        $table.siblings().removeClass('on');
        $table.addClass('on');
    }).first().trigger('click');

    $addTeam.on('click', function (e) {
        e.preventDefault();

        Popup.showTeam(0, function () {
            new Form({
                endpoint: '/Dashboard/SaveTeam',
                fields: [{
                    name: 'id',
                    $input: $('#edit-team-id')
                }, {
                    name: 'name',
                    $input: $('#edit-team-name')
                }, {
                    name: 'type',
                    $input: $('#edit-team-type')
                }, {
                    name: 'level',
                    $input: $('#edit-team-level')
                }, {
                    name: 'levelNew',
                    $input: $('#edit-team-level-new')
                }, {
                    name: 'league',
                    $input: $('#edit-team-league')
                }],
                $btn: $('#edit-team-submit'),
                $errors: $('#edit-team-errors'),
                onSuccess: function (response) {
                    window.location.reload();
                    return;
                },
                onError: function (response) {
                    Popup.showError(response);
                }
            });
        });
    });
}