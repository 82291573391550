IHI.Pages.resetpassword = function (options) {

    var form = new Form({
        endpoint: '/ResetPassword',
        csrf: true,
        fields: [{
            name: 'password',
            $input: $('#reset-pass-password')
        }, {
            name: 'password2',
            $input: $('#reset-pass-password2')
        }, {
            name: 'token',
            $input: $('#reset-pass-token')
        }],
        $btn: $('#reset-pass-submit'),
        $errors: $('#reset-pass-errors'),
        onSuccess: function () {
            Popup.showMessage('Your password has been reset successfully.  You may now login.', function () {
                IHI.redirect('/Dashboard');
            });
        }
    });
    
}