var Chronos = (function () {

    var getFullWeeksForMonth = function (today) {
        var weeks = [];
        var monthIdx = today.getMonth();
        var date = today.getDate();
        var year = today.getFullYear();
        var daysInMonth = getDaysInMonth(monthIdx);
        var firstDayOfMonth = dayOfMonth(today, 1);
        var lastDayOfMonth = dayOfMonth(today, getDaysInMonth(monthIdx, year));

        var calendarDates = [];

        // Get last month's days to start the calendar
        if (firstDayOfMonth.getDay() !== 0) {
            var daysToBackfill = 7 - (7 - firstDayOfMonth.getDay());
            for (var bDays = -daysToBackfill; bDays <= -1; bDays++) {
                var bDate = addDays(firstDayOfMonth, bDays);
                calendarDates.push(bDate);
            }
        }

        // Add this month's dates
        for (var i = 1; i <= daysInMonth; i++) {
            calendarDates.push(dayOfMonth(today, i));
        }

        // Get next month's days to end the calendar
        if (lastDayOfMonth.getDay() !== 6) {
            var daysToFrontfill = 6 - lastDayOfMonth.getDay();
            for (var fDays = 1; fDays <= daysToFrontfill; fDays++) {
                var fDate = addDays(lastDayOfMonth, fDays);
                calendarDates.push(fDate);
            }
        }

        var week = [];
        for (var j = 0; j < calendarDates.length; j++) {
            if (j !== 0 && j % 7 === 0) {
                weeks.push(week);
                week = [];
            }
            week.push(calendarDates[j]);
        }
        if (week.length > 0) {
            weeks.push(week);
        }

        return weeks;
    };

    var dayOfMonth = function (date, dayOfMonth) {
        return new Date(date.getFullYear(), date.getMonth(), dayOfMonth);
    };

    var nextMonth = function (monthIdx) {
        return monthIdx === 11 ? 0 : ++monthIdx;
    };

    var prevMonth = function (monthIdx) {
        return monthIdx === 0 ? 11 : --monthIdx;
    };

    var addDays = function (date, days) {
        var newDate = new Date(date);

        var isAddition = days > 0;
        if (isAddition) {
            for (var i = 1; i <= days; i++) {
                var isLastDayInMonth = newDate.getDate() === getDaysInMonth(newDate.getMonth());
                var isLastDayInYear = newDate.getMonth() === 11 && isLastDayInMonth;
                newDate = new Date(
                    isLastDayInYear ? newDate.getFullYear() + 1 : newDate.getFullYear(),
                    isLastDayInMonth ? nextMonth(newDate.getMonth()) : newDate.getMonth(),
                    isLastDayInMonth ? 1 : newDate.getDate() + 1
                );
            }
        } else {
            for (var j = -1; j >= days; j--) {
                var isFirstDayInMonth = newDate.getDate() === 1;
                var isFirstDayInYear = newDate.getMonth() === 0 && isFirstDayInMonth;
                var daysInPrevMonth = getDaysInMonth(
                    prevMonth(newDate.getMonth()),
                    isFirstDayInYear ? newDate.getFullYear() - 1 : newDate.getFullYear()
                );
                newDate = new Date(
                    isFirstDayInYear ? newDate.getFullYear() - 1 : newDate.getFullYear(),
                    isFirstDayInMonth ? prevMonth(newDate.getMonth()) : newDate.getMonth(),
                    isFirstDayInMonth ? daysInPrevMonth : newDate.getDate() - 1
                );
            }
        }

        return newDate;
    };

    var addMonths = function (date, months) {
        var curDate = new Date(date),
            newYear, newMonth, newDate, daysInMonth;

        var isAddition = months > 0;
        if (isAddition) {
            for (var i = 1; i <= months; i++) {
                newYear = curDate.getMonth() === 11 ? curDate.getFullYear() + 1 : curDate.getFullYear();
                newMonth = nextMonth(curDate.getMonth());
                newDate = curDate.getDate();
                daysInMonth = getDaysInMonth(newMonth);

                curDate = new Date(
                    newYear,
                    newMonth,
                    newDate > daysInMonth ? daysInMonth : newDate
                );
            }
        } else {
            for (var j = -1; j >= months; j--) {
                newYear = curDate.getMonth() === 0 ? 11 : curDate.getFullYear();
                newMonth = prevMonth(curDate.getMonth());
                newDate = curDate.getDate();
                daysInMonth = getDaysInMonth(newMonth);

                curDate = new Date(
                    newYear,
                    newMonth,
                    newDate > daysInMonth ? daysInMonth : newDate
                );
            }
        }

        return curDate;
    };

    var getDaysInMonth = function (monthIdx, year) {
        var isLeapYear = year % 4 === 0;
        switch (monthIdx) {
            case 0: return 31;
            case 1: return isLeapYear ? 29 : 28;
            case 2: return 31;
            case 3: return 30;
            case 4: return 31;
            case 5: return 30;
            case 6: return 31;
            case 7: return 31;
            case 8: return 30;
            case 9: return 31;
            case 10: return 30;
            case 11: return 31;
            default: return 0;
        }
    };

    var getDayName = function (dayOfWeekIdx, short) {
        short = typeof short === 'undefined' ? false : short;

        switch (dayOfWeekIdx) {
            case 0: return short ? 'Sun' : 'Sunday';
            case 1: return short ? 'Mon' : 'Monday';
            case 2: return short ? 'Tue' : 'Tuesday';
            case 3: return short ? 'Wed' : 'Wednesday';
            case 4: return short ? 'Thu' : 'Thursday';
            case 5: return short ? 'Fri' : 'Friday';
            case 6: return short ? 'Sat' : 'Saturday';
            default: return 'Invalid Day';
        }
    };

    var getMonthName = function (monthIdx, short) {
        short = typeof short === 'undefined' ? false : short;

        switch (monthIdx) {
            case 0: return short ? 'Jan' : 'January';
            case 1: return short ? 'Feb' : 'February';
            case 2: return short ? 'Mar' : 'March';
            case 3: return short ? 'Apr' : 'April';
            case 4: return short ? 'May' : 'May';
            case 5: return short ? 'Jun' : 'June';
            case 6: return short ? 'Jul' : 'July';
            case 7: return short ? 'Aug' : 'August';
            case 8: return short ? 'Sep' : 'September';
            case 9: return short ? 'Oct' : 'October';
            case 10: return short ? 'Nov' : 'November';
            case 11: return short ? 'Dec' : 'December';
            default: return 'Invalid Month';
        }
    };

    var dateIsPast = function (date) {
        var now = new Date();
        return date.getTime() < now.getTime() && now.getDate() !== date.getDate();
    };

    var dateIsToday = function (date) {
        var now = new Date();
        return now.getFullYear() === date.getFullYear() &&
               now.getDate() === date.getDate() &&
               now.getMonth() === date.getMonth();
    };

    return {
        addDays: addDays,
        addMonths: addMonths,
        getWeeks: getFullWeeksForMonth,
        getDayName: getDayName,
        getMonthName: getMonthName,
        getDaysInMonth: getDaysInMonth,
        dateIsPast: dateIsPast,
        dateIsToday: dateIsToday
    };

})();