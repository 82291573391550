// A representation of the top-level container node and its children
Popup.Layer = function (content, options, handlers, zIndex) {

    const DOM_DELAY = 50;

    this.domDelay = DOM_DELAY;
    this.options = options;
    this.handlers = handlers;

    // Build top-level container
    var marginClass = options.margins ? ' popup-margins' : '';
    this.container = document.createElement('div');
    this.container.className = 'popup-container ' + options.style + marginClass;
    this.container.style.zIndex = ++zIndex;
    
    // Check for multi content
    this.hasMultiContent = false;
    if (Array.isArray(content))
        this.hasMultiContent = content.length > 1;

    content = Array.isArray(content) ? content : [content];
    for (var idx = 0; idx < content.length; idx++) {
        var contentItem = content[idx];

        // Build physical wrapper for content
        var wrap = document.createElement('div');
        wrap.className = 'popup-wrap';
        if (options.maxWidth > 0) {
            wrap.style.maxWidth = options.maxWidth + 'px';
            wrap.className += ' popup-max-width';
        }

        // Check for title
        this.titleBar = null;
        if (options.title !== '') {
            this.titleBar = document.createElement('div');
            this.titleBar.className = 'popup-title';
            this.titleBar.innerHTML = options.title;
            wrap.appendChild(this.titleBar);
            $(wrap).addClass('wrap-has-title');
        }

        // We need a div to add padding to our content
        var padDiv = document.createElement('div');
        padDiv.className = 'popup-pad';
        if (options.padding === false) {
            $(this.container).addClass('popup-no-padding');
        }

        var contentDiv = document.createElement('div');
        contentDiv.className = 'popup-content';

        // Apply content
        var typeOfContent = typeof contentItem;
        if (typeOfContent === 'string')
            contentDiv.innerHTML = contentItem;
        else
            contentDiv.appendChild(contentItem);

        padDiv.appendChild(contentDiv);
        wrap.appendChild(padDiv);

        // Add close X
        if (options.showX === true) {
            var close = document.createElement('div');
            close.className = 'popup-close';

            var closeIcon = document.createElement('i');
            closeIcon.className = 'fa fa-times';

            close.appendChild(closeIcon);

            if (this.titleBar !== null)
                this.titleBar.appendChild(close);
            else
                wrap.appendChild(close);
        }

        // Optional button bar
        if (this.hasButtons()) {
            var buttonBar = document.createElement('div');
            buttonBar.className = 'popup-buttons';

            if (this.handlers.onOk !== null) {
                buttonBar.appendChild(this.createButton(Popup.buttons.ok, this.handlers.onOk));
            }
            if (this.handlers.onYes !== null) {
                buttonBar.appendChild(this.createButton(Popup.buttons.yes, this.handlers.onYes));
            }
            if (this.handlers.onNo !== null) {
                buttonBar.appendChild(this.createButton(Popup.buttons.no, this.handlers.onNo));
            }
            if (this.handlers.onCancel !== null) {
                buttonBar.appendChild(this.createButton(Popup.buttons.cancel, this.handlers.onCancel));
            }

            $(this.container).addClass('popup-has-buttons');

            wrap.appendChild(buttonBar);
        }

        this.container.appendChild(wrap);
    }
}

// Public interface

Popup.Layer.prototype = {

    // Popup will pass this event down if this layer is the topmost
    clicked: function (e, validDown) {
        var $target = $(e.target);

        var clickedX = $target.is('.popup-close') || $target.closest('.popup-close').length > 0;
        var clickedContainer = e.target === this.container;

        if (clickedX) {
            this.handlers.onX();
            if (this.options.closeOnX)
                Popup.hide();
        }
        else if (clickedContainer && this.options.closeOnOverlay && validDown) {
            Popup.hide();
        }
    },

    // Called from Popup.show()
    // This physically adds the popup container to the DOM,
    // then waits a brief period and adds the on class to display the layer via animation.
    // The layer won't animate without the tiny delay between dom attachment and class assignment.
    show: function () {
        document.body.appendChild(this.container);

        if (this.hasMultiContent)
            $(this.container).addClass('popup-flip');

        Popup.center();

        this.handlers.beforeShow();

        var me = this;
        setTimeout(function () {
            $(me.container).addClass('popup-on');

            setTimeout(function () {
                Popup.center();

                me.handlers.afterShow();
            }, Popup.transitionTimeout);
        }, this.domDelay);
    },

    hide: function () {
        var me = this;

        me.handlers.beforeHide();

        $(me.container).removeClass('popup-on');
        
        setTimeout(function () {
            me.remove();
            me.handlers.afterHide();
        }, Popup.transitionTimeout);
    },

    flip: function () {
        if ($(this.container).hasClass('popup-flipped'))
            $(this.container).removeClass('popup-flipped');
        else
            $(this.container).addClass('popup-flipped');
    },

    remove: function () {
        $(this.container).remove();
    },

    hasButtons: function () {
        return this.handlers.onOk !== null || this.handlers.onYes !== null || this.handlers.onNo !== null || this.handlers.onCancel !== null;
    },

    createButton: function (type, handler) {
        var b = document.createElement('button');
        switch (type) {
            case Popup.buttons.ok:
                b.className = type + ' btn-primary';
                b.innerHTML = "OK";
                break;
            case Popup.buttons.yes:
                b.className = type + ' btn-primary';
                b.innerHTML = "Yes";
                break;
            case Popup.buttons.no:
                b.className = type + ' btn-secondary';
                b.innerHTML = "No";
                break;
            case Popup.buttons.cancel:
                b.className = type + ' btn-secondary';
                b.innerHTML = "Cancel";
                break;
        }
        b.addEventListener('click', function (e) {
            e.preventDefault();
            handler();
        });
        return b;
    },

    // IE or iOS < 10.3 only
    center: function () {
        var isCollapse = this.options.style === Popup.styles.collapse,
            isHybrid = this.options.style === Popup.styles.hybrid,
            isFull = this.options.style === Popup.styles.full;

        var wraps = this.container.querySelectorAll('.popup-wrap');
        for (var idx = 0; idx < wraps.length; idx++) {
            var wrap = wraps[idx];
                topPad = wrap.style.paddingTop === '' ? 0 : parseInt(wrap.style.paddingTop),
                bottomPad = wrap.style.paddingBottom === '' ? 0 : parseInt(wrap.style.paddingBottom);

            var isFullStyleWithMaxWidth = document.querySelector('.popup-style-full .popup-wrap.popup-max-width') !== null;
            if (isCollapse || isHybrid || (App.isIE && isFullStyleWithMaxWidth)) {
                var leftPad = wrap.style.paddingLeft === '' ? 0 : parseInt(wrap.style.paddingLeft);
                var rightPad = wrap.style.paddingRight === '' ? 0 : parseInt(wrap.style.paddingRight);
                var wrapWidth = wrap.clientWidth + leftPad + rightPad;
                var left = Math.floor((App.Screen.width - wrapWidth) / 2);

                wrap.style.left = left + 'px';
            }

            if (isCollapse || (isHybrid && App.Screen.width > 550)) {
                var wrapHeight = wrap.clientHeight + topPad + bottomPad;
                var top = Math.floor((App.Screen.height - wrapHeight) / 2);

                wrap.style.top = top + 'px';
            }
        }
    }

}