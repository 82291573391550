IHI.Pages.application = function (settings) {

    const Step = {
        none: 0,
        player: 1,
        school: 2,
        hockey: 3,
        parents: 4,
        legal: 5,
        done: 6
    };

    const Error = {
        none: 0,

        // General
        emptyFields: 10,
        notLoggedIn: 11,

        // Player
        invalidZip: 20,
        invalidEmail: 21,
        invalidHomePhone: 22,
        invalidMobilePhone: 23,
        invalidBirthDate: 24,
        invalidBirthYear: 25,
        invalidSex: 26,

        // School
        invalidGpa: 30,
        invalidSat: 31,
        invalidAct: 32,

        // Hockey
        invalidWeight: 40,
        invalidHeight: 41,
        invalidGamesPlayed: 50,
        invalidPenaltyMinutes: 51,
        invalidShutouts: 52,
        invalidSaves: 53,
        invalidGaa: 54,
        invalidSavePercent: 55,
        invalidGoals: 56,
        invalidAssists: 57,
        invalidPoints: 58,
        invalidCoachPhone: 59,

        // Parents
        invalidFatherPhone: 60,
        invalidFatherPhoneAlt: 61,
        invalidFatherEmail: 62,
        invalidMotherPhone: 63,
        invalidMotherPhoneAlt: 64,
        invalidMotherEmail: 65,

        // Legal
        invalidParentConsent: 69,

        // Other
        databaseFailure: 80
    }

    var $app = $('#application');
    var $statusBar = $app.find('.status');
    var $statuses = $statusBar.children('ul').children();
    var $errors = $app.find('.errors');
    var $billingSame = $app.find('label[for=billing-same]');
    var $actions = $app.find('.actions');
    var $begin = $actions.children('.begin');
    var $back = $actions.children('.back');
    var $next = $actions.children('.next');
    var $return = $actions.children('.return');
    
    var step = 0;
    var fields = null;
    var activeRequest = false;

    var init = function () {
        step = parseStepString($statusBar.attr('data-step'));

        handleApplicationReset();

        $return.on('click', linkHandler);
        $begin.on('click', linkHandler);
        $back.on('click', linkHandler);
        $next.on('click', nextHandler);

        switch (step) {
            case Step.player:
                actionPlayer(); break;
            case Step.school:
                actionSchool(); break;
            case Step.hockey:
                actionHockey(); break;
            case Step.parents:
                actionParents(); break;
            case Step.legal:
                actionLegal(); break;
            case Step.done:
                actionDone(); break;
        }
    }

    var handleApplicationReset = function () {
        if (Storage.appReset.exists() || step > 1)
            return;

        resetApplicationFields();

        Storage.appReset.set('true');
    }

    var actionPlayer = function () {
        setStatus(Step.player);

        fields = getPlayerFields();
        loadFields();

        new Cleave('#player-homephone', {
            phone: true,
            phoneRegionCode: 'US'
        });

        new Cleave('#player-mobilephone', {
            phone: true,
            phoneRegionCode: 'US'
        });

        new Cleave('#player-dob', {
            date: true,
            datePattern: ['m', 'd', 'Y']
        });
    };

    var getPlayerFields = function () {
        return [
            new AppField($app.find('#player-first'), Storage.appFirstName),
            new AppField($app.find('#player-last'), Storage.appLastName),
            new AppField($app.find('#player-dob'), Storage.appDob),
            new AppField($app.find('#player-sex'), Storage.appSex),
            new AppField($app.find('#player-address'), Storage.appAddress),
            new AppField($app.find('#player-address2'), Storage.appAddress2),
            new AppField($app.find('#player-city'), Storage.appCity),
            new AppField($app.find('#player-state'), Storage.appState),
            new AppField($app.find('#player-zip'), Storage.appZip),
            new AppField($app.find('#player-email'), Storage.appEmail),
            new AppField($app.find('#player-homephone'), Storage.appHomePhone),
            new AppField($app.find('#player-mobilephone'), Storage.appMobilePhone)
        ];
    }

    var actionSchool = function () {
        setStatus(Step.school);

        fields = getSchoolFields();
        loadFields();
    };

    var getSchoolFields = function () {
        return [
            new AppField($app.find('#player-school'), Storage.appSchool),
            new AppField($app.find('#player-grad-year'), Storage.appGradYear),
            new AppField($app.find('#player-gpa'), Storage.appGpa),
            new AppField($app.find('#player-sat'), Storage.appSat),
            new AppField($app.find('#player-act'), Storage.appAct),
            new AppField($app.find('#player-preparatoryinterests'), Storage.appPreparatoryInterests),
            new AppField($app.find('#player-juniorinterests'), Storage.appJuniorInterests),
            new AppField($app.find('#player-collegeinterests'), Storage.appCollegeInterests)
        ];
    }

    var actionHockey = function () {
        setStatus(Step.hockey);

        fields = getHockeyFields();
        loadFields();

        var $posGoalie = $('.position-goalie'),
            $posOther = $('.position-other');

        // show the correct stat fields based on position
        $('#player-position').on('change', function (e) {
            var position = $(this).val();

            if (position === '') {
                $posGoalie.hide();
                $posOther.hide();
                return;
            }

            var positionVal = parseInt(position);
            if (positionVal === 0) {
                $posGoalie.show();
                $posOther.hide();
            }
            else {
                $posGoalie.hide();
                $posOther.show();
            }
        }).trigger('change');

        new Cleave('#player-currentcoachphone', {
            phone: true,
            phoneRegionCode: 'US'
        });

        new Cleave('#player-splitcoachphone', {
            phone: true,
            phoneRegionCode: 'US'
        });
    };

    var getHockeyFields = function () {
        return [
            new AppField($app.find('#player-weight'), Storage.appWeight),
            new AppField($app.find('#player-height'), Storage.appHeight),
            new AppField($app.find('#player-position'), Storage.appPosition),
            new AppField($app.find('#player-glove'), Storage.appGlove),
            new AppField($app.find('#player-shot'), Storage.appShot),
            new AppField($app.find('#player-gamesplayed'), Storage.appGamesPlayed),
            new AppField($app.find('#player-penaltyminutes'), Storage.appPenaltyMinutes),
            new AppField($app.find('#player-shutouts'), Storage.appShutouts),
            new AppField($app.find('#player-saves'), Storage.appSaves),
            new AppField($app.find('#player-gaa'), Storage.appGaa),
            new AppField($app.find('#player-savepercent'), Storage.appSavePercent),
            new AppField($app.find('#player-goals'), Storage.appGoals),
            new AppField($app.find('#player-assists'), Storage.appAssists),
            new AppField($app.find('#player-points'), Storage.appPoints),
            new AppField($app.find('#player-currentteam'), Storage.appCurrentTeam),
            new AppField($app.find('#player-currentcoachname'), Storage.appCurrentCoachName),
            new AppField($app.find('#player-currentcoachphone'), Storage.appCurrentCoachPhone),
            new AppField($app.find('#player-splitteam'), Storage.appSplitTeam),
            new AppField($app.find('#player-splitcoachname'), Storage.appSplitCoachName),
            new AppField($app.find('#player-splitcoachphone'), Storage.appSplitCoachPhone),
            new AppField($app.find('#player-othersports'), Storage.appOtherSports),
            new AppField($app.find('#player-league'), Storage.appLeague),
            new AppField($app.find('#player-draftedby'), Storage.appDraftedBy),
            new AppField($app.find('#player-committedto'), Storage.appCommittedTo)
        ];
    }

    var actionParents = function () {
        setStatus(Step.parents);

        fields = getParentFields();
        loadFields();

        new Cleave('#player-fatherphone', {
            phone: true,
            phoneRegionCode: 'US'
        });

        new Cleave('#player-fatherphonealt', {
            phone: true,
            phoneRegionCode: 'US'
        });

        new Cleave('#player-motherphone', {
            phone: true,
            phoneRegionCode: 'US'
        });

        new Cleave('#player-motherphonealt', {
            phone: true,
            phoneRegionCode: 'US'
        });
    };

    var getParentFields = function () {
        return [
            new AppField($app.find('#player-fathername'), Storage.appFatherName),
            new AppField($app.find('#player-fatheremail'), Storage.appFatherEmail),
            new AppField($app.find('#player-fatherphone'), Storage.appFatherPhone),
            new AppField($app.find('#player-fatherphonealt'), Storage.appFatherPhoneAlt),
            new AppField($app.find('#player-fatheroccupation'), Storage.appFatherOccupation),
            new AppField($app.find('#player-mothername'), Storage.appMotherName),
            new AppField($app.find('#player-motheremail'), Storage.appMotherEmail),
            new AppField($app.find('#player-motherphone'), Storage.appMotherPhone),
            new AppField($app.find('#player-motherphonealt'), Storage.appMotherPhoneAlt),
            new AppField($app.find('#player-motheroccupation'), Storage.appMotherOccupation)
        ];
    }

    var actionLegal = function () {
        setStatus(Step.legal);

        fields = getLegalFields();
        loadFields();
    };

    var getLegalFields = function () {
        return [
            new AppField($app.find('#player-parentyes'), Storage.appParentConsent),
            new AppField($app.find('#player-parentsignature'), Storage.appParentSignature)
        ];
    }

    var actionDone = function () {
        setStatus(Step.done);
    };

    var setStatus = function (val) {
        step = val;
        Storage.appStep.set(val);
        $statuses.eq(step - 1).addClass('current');
    }

    var parseStepString = function (str) {
        switch (str.toLowerCase()) {
            default:
            case 'player': return Step.player;
            case 'school': return Step.school;
            case 'hockey': return Step.hockey;
            case 'parents': return Step.parents;
            case 'legal': return Step.legal;
            case 'done': return Step.done;
        }
    }

    var getData = function () {
        var data = {};       

        if (step === Step.legal) {
            data = getAllFields();
        }
        else {
            fields.forEach(function (appField) {
                data[appField.id] = appField.$input.val();
            });
        }

        // Add step
        data['step'] = step;

        // Add security token
        var sTokenName = '__RequestVerificationToken';
        data[sTokenName] = $('input[name=' + sTokenName + ']').val();

        return data;
    }

    var getAllFields = function () {
        return {
            'player-first': Storage.appFirstName.get(),
            'player-last': Storage.appLastName.get(),
            'player-dob': Storage.appDob.get(),
            'player-sex': Storage.appSex.get(),
            'player-address': Storage.appAddress.get(),
            'player-address2': Storage.appAddress2.get(),
            'player-city': Storage.appCity.get(),
            'player-state': Storage.appState.get(),
            'player-zip': Storage.appZip.get(),
            'player-email': Storage.appEmail.get(),
            'player-homephone': Storage.appHomePhone.get(),
            'player-mobilephone': Storage.appMobilePhone.get(),

            'player-school': Storage.appSchool.get(),
            'player-grad-year': Storage.appGradYear.get(),
            'player-gpa': Storage.appGpa.get(),
            'player-sat': Storage.appSat.get(),
            'player-act': Storage.appAct.get(),
            'player-preparatoryinterests': Storage.appPreparatoryInterests.get(),
            'player-juniorinterests': Storage.appJuniorInterests.get(),
            'player-collegeinterests': Storage.appCollegeInterests.get(),

            'player-weight': Storage.appWeight.get(),
            'player-height': Storage.appHeight.get(),
            'player-position': Storage.appPosition.get(),
            'player-glove': Storage.appGlove.get(),
            'player-shot': Storage.appShot.get(),
            'player-gamesplayed': Storage.appGamesPlayed.get(),
            'player-penaltyminutes': Storage.appPenaltyMinutes.get(),
            'player-shutouts': Storage.appShutouts.get(),
            'player-saves': Storage.appSaves.get(),
            'player-gaa': Storage.appGaa.get(),
            'player-savepercent': Storage.appSavePercent.get(),
            'player-goals': Storage.appGoals.get(),
            'player-assists': Storage.appAssists.get(),
            'player-points': Storage.appPoints.get(),
            'player-currentteam': Storage.appCurrentTeam.get(),
            'player-currentcoachname': Storage.appCurrentCoachName.get(),
            'player-currentcoachphone': Storage.appCurrentCoachPhone.get(),
            'player-splitteam': Storage.appSplitTeam.get(),
            'player-splitcoachname': Storage.appSplitCoachName.get(),
            'player-splitcoachphone': Storage.appSplitCoachPhone.get(),
            'player-othersports': Storage.appOtherSports.get(),
            'player-league': Storage.appLeague.get(),
            'player-draftedby': Storage.appDraftedBy.get(),
            'player-committedto': Storage.appCommittedTo.get(),

            'player-fathername': Storage.appFatherName.get(),
            'player-fatheremail': Storage.appFatherEmail.get(),
            'player-fatherphone': Storage.appFatherPhone.get(),
            'player-fatherphonealt': Storage.appFatherPhoneAlt.get(),
            'player-fatheroccupation': Storage.appFatherOccupation.get(),
            'player-mothername': Storage.appMotherName.get(),
            'player-motheremail': Storage.appMotherEmail.get(),
            'player-motherphone': Storage.appMotherPhone.get(),
            'player-motherphonealt': Storage.appMotherPhoneAlt.get(),
            'player-motheroccupation': Storage.appMotherOccupation.get(),

            'player-parentsignature': $('#player-parentsignature').val()
        };
    }

    var resetApplicationFields = function () {
        Storage.appFirstName.remove();
        Storage.appLastName.remove();
        Storage.appDob.remove();
        Storage.appSex.remove();
        Storage.appAddress.remove();
        Storage.appAddress2.remove();
        Storage.appCity.remove();
        Storage.appState.remove();
        Storage.appZip.remove();
        Storage.appEmail.remove();
        Storage.appHomePhone.remove();
        Storage.appMobilePhone.remove();
        Storage.appDob.remove();

        Storage.appSchool.remove();
        Storage.appGradYear.remove();
        Storage.appGpa.remove();
        Storage.appSat.remove();
        Storage.appAct.remove();
        Storage.appPreparatoryInterests.remove();
        Storage.appJuniorInterests.remove();
        Storage.appCollegeInterests.remove();

        Storage.appWeight.remove();
        Storage.appHeight.remove();
        Storage.appPosition.remove();
        Storage.appGlove.remove();
        Storage.appShot.remove();
        Storage.appGamesPlayed.remove();
        Storage.appPenaltyMinutes.remove();
        Storage.appShutouts.remove();
        Storage.appSaves.remove();
        Storage.appGaa.remove();
        Storage.appSavePercent.remove();
        Storage.appGoals.remove();
        Storage.appAssists.remove();
        Storage.appPoints.remove();
        Storage.appCurrentTeam.remove();
        Storage.appCurrentCoachName.remove();
        Storage.appCurrentCoachPhone.remove();
        Storage.appSplitTeam.remove();
        Storage.appSplitCoachName.remove();
        Storage.appSplitCoachPhone.remove();
        Storage.appOtherSports.remove();
        Storage.appLeague.remove();
        Storage.appDraftedBy.remove();
        Storage.appCommittedTo.remove();

        Storage.appFatherName.remove();
        Storage.appFatherEmail.remove();
        Storage.appFatherPhone.remove();
        Storage.appFatherPhoneAlt.remove();
        Storage.appFatherOccupation.remove();
        Storage.appMotherName.remove();
        Storage.appMotherEmail.remove();
        Storage.appMotherPhone.remove();
        Storage.appMotherPhoneAlt.remove();
        Storage.appMotherOccupation.remove();

        Storage.appParentConsent.remove();
        Storage.appParentSignature.remove();
    };

    var linkHandler = function (e) {
        e.preventDefault();
        document.location.href = $(this).attr('href');
    }

    var nextHandler = function (e) {
        e.preventDefault();
        var $btn = $(this);

        if (activeRequest)
            return;

        if (step === Step.legal && !$('#player-parentyes').is(':checked')) {
            showErrors({
                errorIds: ['player-parentconsent'],
                errorMsgs: [Error.invalidParentConsent]
            });
            $errors.last().show();
            return;
        }

        activeRequest = true;
        clearErrors();

        if (step === Step.legal) {
            try {
                postForm($btn)
                activeRequest = false;
            }
            catch (err)
            {
                console.log(err);
            }
        }
        else {
            postForm($btn);
        }     
    }

    var postForm = function ($btn) {
        $.post(
            '/ApplicationValidate',
            getData(),
            function (response) {
                if (response.success) {

                    if (step < Step.legal)
                        saveFields();                        

                    document.location.href = $btn.attr('href');
                }
                else {
                    showErrors(response);
                }

                activeRequest = false;
            },
            'json'
        );
    }

    var saveFields = function () {
        fields.forEach(function (appField) {
            var $f = appField.$input;
            var val = $f.val();

            if ($f.attr('type') === 'checkbox' || $f.attr('type') === 'radio')
                val = $f.is(':checked') ? "true" : "false";

            appField.storage.set(val);
        });
    }

    var loadFields = function () {
        fields.forEach(function (appField) {
            if (appField.id === 'player-parentyes') {
                if (appField.storage.get() === true)
                    $('#player-parentyes').prop('checked', true);
            }
            else if (appField.id === 'player-draftedby' || appField.id === 'player-committedto') {
                if (appField.storage.get() !== null)
                    appField.$input.val(appField.storage.get());
            }
            else
                appField.$input.val(appField.storage.get());
        });
    }

    var clearErrors = function () {
        $('.field.error').removeClass('error');
        $errors.html('').hide();
    }

    var showErrors = function (response) {
        if (response.errorMsgs.length > 0) {
            var html = '';
            $(response.errorMsgs).each(function (idx, msgId) {
                html += '<p>' + getMsg(msgId) + '</p>';
            });
            $errors.html(html);
        }

        if (response.errorIds.length > 0) {
            $(response.errorIds).each(function (idx, id) {
                var $f = $('#' + id);
                $f.closest('.field').addClass('error');
                $f.closest('fieldset').children('.errors').show();
            });
        }
        else
            $errors.last().show();
    }

    var getMsg = function (msgId) {
        switch (msgId) {
            // general
            case Error.emptyFields:
                return "One or more required fields are empty.";

            // auth
            case Error.notLoggedIn:
                return "Your user session has expired, please <a href=\"/Dashboard?mode=Login\">login</a> again.";

            // player
            case Error.invalidZip:
                return "Zip code is invalid.";
            case Error.invalidEmail:
                return "Email is invalid.";
            case Error.invalidHomePhone:
                return "Home phone is invalid.";
            case Error.invalidMobilePhone:
                return "Mobile phone is invalid.";
            case Error.invalidBirthDate:
                return "Date of birth is invalid.";
            case Error.invalidBirthYear:
                var year = new Date().getFullYear();
                return "Year of birth must be " + (year - 17) + ", " + (year - 16) + ", " + (year - 15) + " or " + (year - 14) + ".";
            case Error.invalidSex:
                return "Please choose a sex for the player.";

            // school 
            case Error.invalidGpa:
                return "GPA is invalid.";
            case Error.invalidSat:
                return "SAT is invalid.";
            case Error.invalidAct:
                return "ACT is invalid.";

                // Hockey
            case Error.invalidWeight:
                return "Weight is invalid.";
            case Error.invalidHeight:
                return "Height is invalid.";
            case Error.invalidGamesPlayed:
                return "Games Played is invalid.";
            case Error.invalidPenaltyMinutes:
                return "Penalty Minutes is invalid.";
            case Error.invalidShutouts:
                return "Shutouts is invalid.";
            case Error.invalidSaves:
                return "Saves is invalid.";
            case Error.invalidGaa:
                return "GAA is invalid.";
            case Error.invalidSavePercent:
                return "Save Percent is invalid.";
            case Error.invalidGoals:
                return "Goals is invalid.";
            case Error.invalidAssists:
                return "Assists is invalid.";
            case Error.invalidPoints:
                return "Points are invalid.";
            case Error.invalidCoachPhone:
                return "Coach's Phone is invalid.";

            // Parents
            case Error.invalidFatherPhone:
                return "Father's Phone is invalid.";
            case Error.invalidFatherPhoneAlt:
                return "Father's Alternate Phone is invalid.";
            case Error.invalidFatherEmail:
                return "Father's Email is invalid.";
            case Error.invalidMotherPhone:
                return "Mother's Phone is invalid.";
            case Error.invalidMotherPhoneAlt:
                return "Mother's Alternate Phone is invalid.";
            case Error.invalidMotherEmail:
                return "Mother's Email is invalid.";

            // Legal
            case Error.invalidParentConsent:
                return '"Yes" must be selected for the agreement in order to continue.';

            // Other
            case Error.databaseFailure:
                return 'We\'re sorry but there was a problem saving your application. This could be due to some missing/invalid information in the application.  Please <a href="/Application/Player#application">return to the beginning</a> and step through the application to ensure all information is filled out.  If you believe you have received this message in error, please <a href="/contact">contact us</a>.';
        }
    }

    init();
}

var AppField = function ($input, storage) {
    this.id = $input.attr('id');
    this.$input = $input;
    this.storage = storage;
}