IHI.Pages.dashboard = function (options) {

    var globals = function () {
        $('a.log-out').on('click', function (e) {
            Storage.userToken.remove();
        });

        $('.table-list-options').on('click', function (e) {
            e.preventDefault();
            $(this).next().toggleClass('on');
        });

        $(document).on('mouseup', function (e) {
            var $target = $(e.target);
            if (!$target.is('.menu-options') && $target.closest('.menu').length === 0)
                hideAll();
        });

        $('.actions button').on('click', function (e) {
            var $btn = $(this);
            if ($btn.attr('href') !== undefined) {
                IHI.redirect($btn.attr('href'));
            }
        });
    }

    var hideAll = function () {
        $('.table-list-options-menu').removeClass('on');
    }

    globals();
    switch ($('#dashboard').attr('data-module')) {
        case 'staff': IHI.Pages.dashboardStaff(options); break;
        case 'add-staff': IHI.Pages.dashboardAddStaff(options); break;
        case 'players': IHI.Pages.dashboardPlayers(options); break;
        case 'applications': IHI.Pages.dashboardApplications(options); break;
        case 'application-change-plan': IHI.Pages.dashboardApplicationChangePlan(options); break;
        case 'save-game-report': IHI.Pages.dashboardSaveGameReport(options); break;
        case 'game-reports': IHI.Pages.dashboardGameReports(options); break;
        case 'view-game-report': IHI.Pages.dashboardViewGameReport(options); break;
        case 'scouts': IHI.Pages.dashboardScouts(options); break;
        case 'add-commitment': IHI.Pages.dashboardAddCommitment(options); break;
        case 'events': IHI.Pages.dashboardEvents(options); break;
        case 'messages': IHI.Pages.dashboardMessages(options); break;
        case 'teams': IHI.Pages.dashboardTeams(options); break;
        case 'data-migration': IHI.Pages.dashboardDataMigration(options); break;
    }

}