var Storage = (function () {

    const Type = {
        local: 'local',
        session: 'session'
    };

    const DefaultCookieLife = 30; // days

    var StorageInterface = function (type, key) {
        switch (type) {
            case Type.local: this.storage = window.localStorage; break;
            case Type.session: this.storage = window.sessionStorage; break;
        }
        this.key = key;
    };
    StorageInterface.prototype = {
        set: function (val) {
            if (typeof val === 'object')
                val = JSON.stringify(val);

            this.storage.setItem(this.key, val);
        },
        get: function() {
            var val = this.storage.getItem(this.key);

            try {
                return JSON.parse(val);
            }
            catch (err) {
                return val;
            }
        },
        remove: function () {
            this.storage.removeItem(this.key);
        },
        exists: function () {
            return this.storage.getItem(this.key) !== null;
        }
    };

    var CookieInterface = function (key) {
        this.key = key;
    }
    CookieInterface.prototype = {
        set: function (val, days) {
            if (typeof val === 'object')
                val = JSON.stringify(val);

            if (typeof days === 'undefined')
                days = DefaultCookieLife;

            Cookies.set(this.key, val, { expires: days });
        },
        get: function () {
            return Cookies.get(this.key);
        },
        remove: function () {
            Cookies.remove(this.key);
        },
        exists: function () {
            return Cookies.get(this.key) !== undefined;
        }
    };

    // Public
    return {
        // auth
        userToken:              new CookieInterface('user-token', 7),

        // pricing
        appPlan:                new StorageInterface(Type.local, 'plan'),

        // application step
        appStep:                new StorageInterface(Type.local, 'step'),

        // application - player
        appFirstName:           new StorageInterface(Type.local, 'player-first'),
        appLastName:            new StorageInterface(Type.local, 'player-last'),
        appAddress:             new StorageInterface(Type.local, 'player-address'),
        appAddress2:            new StorageInterface(Type.local, 'player-address2'),
        appCity:                new StorageInterface(Type.local, 'player-city'),
        appState:               new StorageInterface(Type.local, 'player-state'),
        appZip:                 new StorageInterface(Type.local, 'player-zip'),
        appEmail:               new StorageInterface(Type.local, 'player-email'),
        appHomePhone:           new StorageInterface(Type.local, 'player-homephone'),
        appMobilePhone:         new StorageInterface(Type.local, 'player-mobilephone'),
        appDob:                 new StorageInterface(Type.local, 'player-dob'),
        appSex:                 new StorageInterface(Type.local, 'player-sex'),

        // application - school
        appSchool:              new StorageInterface(Type.local, 'player-school'),
        appGradYear:            new StorageInterface(Type.local, 'player-grad-year'),
        appGpa:                 new StorageInterface(Type.local, 'player-gpa'),
        appSat:                 new StorageInterface(Type.local, 'player-sat'),
        appAct:                 new StorageInterface(Type.local, 'player-act'),
        appPreparatoryInterests:new StorageInterface(Type.local, 'player-preparatoryinterests'),
        appJuniorInterests:     new StorageInterface(Type.local, 'player-juniorinterests'),
        appCollegeInterests:    new StorageInterface(Type.local, 'player-collegeinterests'),

        // application - hockey
        appWeight:              new StorageInterface(Type.local, 'player-weight'),
        appHeight:              new StorageInterface(Type.local, 'player-height'),
        appPosition:            new StorageInterface(Type.local, 'player-position'),
        appGlove:               new StorageInterface(Type.local, 'player-glove'),
        appShot:                new StorageInterface(Type.local, 'player-shot'),
        appGamesPlayed:         new StorageInterface(Type.local, 'player-gamesplayed'),
        appPenaltyMinutes:      new StorageInterface(Type.local, 'player-penaltyminutes'),
        appShutouts:            new StorageInterface(Type.local, 'player-shutouts'),
        appSaves:               new StorageInterface(Type.local, 'player-saves'),
        appGaa:                 new StorageInterface(Type.local, 'player-gaa'),
        appSavePercent:         new StorageInterface(Type.local, 'player-savepercent'),
        appGoals:               new StorageInterface(Type.local, 'player-goals'),
        appAssists:             new StorageInterface(Type.local, 'player-assists'),
        appPoints:              new StorageInterface(Type.local, 'player-points'),
        appCurrentTeam:         new StorageInterface(Type.local, 'player-currentteam'),
        appCurrentCoachName:    new StorageInterface(Type.local, 'player-currentcoachname'),
        appCurrentCoachPhone:   new StorageInterface(Type.local, 'player-currentcoachphone'),
        appSplitTeam:           new StorageInterface(Type.local, 'player-splitteam'),
        appSplitCoachName:      new StorageInterface(Type.local, 'player-splitcoachname'),
        appSplitCoachPhone:     new StorageInterface(Type.local, 'player-splitcoachphone'),
        appOtherSports:         new StorageInterface(Type.local, 'player-othersports'),
        appLeague:              new StorageInterface(Type.local, 'player-league'),
        appDraftedBy:           new StorageInterface(Type.local, 'player-draftedby'),
        appCommittedTo:         new StorageInterface(Type.local, 'player-committedto'),

        // application - parents
        appFatherName:          new StorageInterface(Type.local, 'player-fathername'),
        appFatherEmail:         new StorageInterface(Type.local, 'player-fatheremail'),
        appFatherPhone:         new StorageInterface(Type.local, 'player-fatherphone'),
        appFatherPhoneAlt:      new StorageInterface(Type.local, 'player-fatherphonealt'),
        appFatherOccupation:    new StorageInterface(Type.local, 'player-fatheroccupation'),
        appMotherName:          new StorageInterface(Type.local, 'player-mothername'),
        appMotherEmail:         new StorageInterface(Type.local, 'player-motheremail'),
        appMotherPhone:         new StorageInterface(Type.local, 'player-motherphone'),
        appMotherPhoneAlt:      new StorageInterface(Type.local, 'player-motherphonealt'),
        appMotherOccupation:    new StorageInterface(Type.local, 'player-motheroccupation'),

        // application - legal
        appParentConsent:       new StorageInterface(Type.local, 'player-parentconsent'),
        appParentSignature:     new StorageInterface(Type.local, 'player-parentsignature'),

        // application - forced reset
        appReset:               new StorageInterface(Type.local, 'application-reset'),

        paymentBypass: new CookieInterface('payment-bypass-code')
    };

})();