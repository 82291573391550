IHI.Pages.auth = function (options) {

    const params = new URLSearchParams(document.location.search);
    const DefaultRedirect = '/Dashboard';

    var mode = params.get('mode') !== null ? params.get('mode') : 'Login',
        $login = $('#auth-login'),
        $join = $('#auth-join'),
        $org = $('#auth-join-org'),
        $sex = $('#auth-join-sex'),
        $scoutSuccess = $('#scout-success'),
        isScout = $org.length > 0;
    
    var success = function (response) {
        if (isScout) {
            $join.remove();
            $scoutSuccess.show();
            return;
        }

        Auth.setToken(response.token);

        if (options.redirect !== '') {
            IHI.redirect(options.redirect);
        }
        else {
            IHI.redirect(DefaultRedirect);
        }
    }

    var formLogin = new Form({
        endpoint: '/Auth/Login',
        csrf: true,
        fields: [{
            name: 'firstname',
            $input: $('#auth-login-firstname')
        }, {
            name: 'lastname',
            $input: $('#auth-login-lastname')
        }, {
            name: 'email',
            $input: $('#auth-login-email')
        }, {
            name: 'password',
            $input: $('#auth-login-password')
        }],
        $btn: $('#auth-login-submit'),
        $errors: $('#auth-login-errors'),
        onSuccess: success
    });

    var joinFields = [{
        name: 'firstname',
        $input: $('#auth-join-firstname')
    }, {
        name: 'lastname',
        $input: $('#auth-join-lastname')
    }, {
        name: 'email',
        $input: $('#auth-join-email')
    }, {
        name: 'password',
        $input: $('#auth-join-password')
    }, {
        name: 'key',
        $input: $('#auth-join-key')
    }];

    if (isScout) {
        joinFields.push({
            name: 'org',
            $input: $('#auth-join-org')
        });

        joinFields.push({
            name: 'sex',
            $input: $('#auth-join-sex')
        });

        joinFields.push({
            name: 'position',
            $input: $('#auth-join-position')
        });
    }       

    var formJoin = new Form({
        endpoint: '/Auth/Join',
        csrf: true,
        fields: joinFields,
        $btn: $('#auth-join-submit'),
        $errors: $('#auth-join-errors'),
        onSuccess: success
    });

    var swap = function () {
        if (mode === 'Login') {
            $login.fadeOut(function () {
                $join.fadeIn();
                mode = 'Join';
            });
        }
        else {
            $join.fadeOut(function () {
                $login.fadeIn();
                mode = 'Login';
            });
        }
    }

    $('.swap').on('click', function (e) {
        e.preventDefault();
        swap();
    });

    // activate the right form
    if (mode === 'Login') {
        $login.show();
    }
    else {
        $join.show();
    }
    
}