var Popup = (function () {

    const STYLES = {
        full: 'popup-style-full',
        hybrid: 'popup-style-hybrid',
        collapse: 'popup-style-collapse'
    }
    const BUTTONS = {
        ok: 'popup-button-ok',
        yes: 'popup-button-yes',
        no: 'popup-button-no',
        cancel: 'popup-button-cancel'
    }
    const BASE_ZINDEX = 5000;
    const TRANSITION_TIMEOUT = 300;

    var layers = [],
        contentCache = {},
        validDown,
        touched;

    var show = function (content, options, handlers) {
        if (typeof content !== 'string' && typeof content !== 'object') {
            Debug.error('Invalid content option supplied to Popup.push(options).  Must be a string or HTML node.');
            return;
        }

        options = $.extend({
            style: STYLES.collapse,
            showX: true,
            closeOnX: true,
            closeOnOverlay: true,
            padding: true,
            maxWidth: 0,
            margins: false,
            title: ''
        }, options || {});

        handlers = $.extend({
            beforeShow: function () { },
            afterShow: function () { },
            beforeHide: function () { },
            afterHide: function () { },
            onX: function () { },

            // button handlers default to null so they aren't displayed without a handler
            onOk: null,
            onYes: null,
            onNo: null,
            onCancel: null
        }, handlers || {});

        var pLayer = new Popup.Layer(
            content,
            options,
            handlers,
            nextZIndex()
        );
        layers.push(pLayer);

        // We have at least one layer now, so lock scrolling on the screen
        // and start listening for clicks
        if (layers.length === 1) {
            attachHandlers();
            pLayer.container.addEventListener('touchstart', function (e) {
                touched = true;
                validDown = $(e.target).hasClass('popup-container');
            });
            pLayer.container.addEventListener('touchend', function (e) {
                onClick(e, lastLayer());
            });

            scrollLock();
        }

        pLayer.show();
    }

    var onClick = function (e, pLayer) {
        var $target = $(e.target);
        if ($target.hasClass('popup-container') || $target.is('.popup-close') || $target.closest('.popup-close').length > 0) {
            e.preventDefault();
            pLayer.clicked(e, validDown);
            validDown = false;
        }
    }

    var lastLayer = function () {
        return layers[layers.length - 1];
    }

    var hide = function (skipFade, all) {
        all = all === undefined ? false : all;

        if (layers.length === 0)
            return;

        var pLayer = getTopLayer();
        if (skipFade)
            pLayer.remove();
        else
            pLayer.hide();

        // No more layers, so unlock scrolling on the screen
        // and stop listening for clicks
        if (layers.length === 0) {
            detachHandlers();
            scrollUnlock();
        }
        else if (all) {
            skipFade = true;
            hide(skipFade, all);
        }
    }

    var getTopLayer = function () {
        // avoid a popup-spinner
        for (var i = layers.length - 1; i >= 0; i--)
            if (!$(layers[i].container).hasClass('popup-spinner'))
                return layers.splice(i, 1)[0];
    }

    var hideSpinner = function () {
        if (Popup.spinnerDelay !== null) {
            clearTimeout(Popup.spinnerDelay);
            Popup.spinnerDelay = null;
            return;
        }

        var removed = false;

        for (var i = layers.length - 1; i >= 0; i--) {
            if ($(layers[i].container).hasClass('popup-spinner')) {
                layers.splice(i, 1)[0].remove();
                removed = true;
            }
        }

        // No more layers, so unlock scrolling on the screen
        // and stop listening for clicks
        if (removed && layers.length === 0) {
            detachHandlers();
            scrollUnlock();
        }
    }

    var mouseUp = function (e) {
        if (touched) {
            touched = false;
            return;
        }
        onClick(e, lastLayer());
    };

    var mouseDown = function (e) {
        validDown = $(e.target).hasClass('popup-container');
    }

    var attachHandlers = function () {
        document.addEventListener('mouseup', mouseUp);
        document.addEventListener('mousedown', mouseDown);
    }

    var detachHandlers = function () {
        document.removeEventListener('mouseup', mouseUp);
        document.removeEventListener('mousedown', mouseDown);
    }

    var flip = function () {
        var pLayer = lastLayer();

        if (pLayer.hasMultiContent)
            pLayer.flip();
    }

    var center = function () {
        //if (App.isIE || App.iOSLessThanTenThree()) { // IE or < iOS 10.3 only
        //    var pLayer = lastLayer();
        //    pLayer.center();
        //}        
    }

    var nextZIndex = function () {
        return BASE_ZINDEX + layers.length;
    }

    var scrollLock = function () {
        $(document.body).addClass('popup-open');
        center();
    }

    var scrollUnlock = function () {
        $(document.body).removeClass('popup-open');
        center();
    }

    var getCached = function (cacheId) {
        if (typeof contentCache[cacheId] !== 'string') {
            var template = document.querySelector('#' + cacheId);
            contentCache[cacheId] = template.innerHTML;
            $(template).remove();
        }
        return contentCache[cacheId];
    }

    // Legacy
    window.addEventListener('resize', function () {
        center();
    });

    // Public interface

    return {
        show: function (content, options, handlers) {
            show(content, options, handlers);
        },
        hide: function (skipFade) {
            skipFade = skipFade === undefined ? false : skipFade;
            hide(skipFade);
        },
        hideAll: function (skipFade) {
            skipFade = skipFade === undefined ? false : skipFade;
            var all = true;

            hide(skipFade, all);
        },
        hideSpinner: hideSpinner,
        flip: function () {
            flip();
        },
        center: function () {
                center();
		},
        active: function () {
            return layers.length > 0;
        },
        getCached: getCached,
        styles: STYLES,
        buttons: BUTTONS,
        transitionTimeout: TRANSITION_TIMEOUT,
        spinnerDelay: null
    };

})();