IHI.Pages.dashboardStaff = function (options) {

    $('.menu-remove').on('click', function (e) {
        var $link = $(this);
        var name = $link.closest('tr').children('td').first().text();
        Popup.showConfirm('Are you sure you want to remove this staff member?', 'Remove ' + name, function () {
            remove($link);
        });
    });

    var remove = function ($menuItem) {
        var $row = $menuItem.closest('tr');
        $.getJSON('/Dashboard/RemoveStaff/' + $row.attr('data-id'), function (response) {
            if (response.success) {
                $row.fadeOut(function () {
                    $row.remove();
                });
            }
            else {
                Popup.showError('There was a problem removing the staff member.');
            }
        });
    }
    
}