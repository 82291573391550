IHI.Pages.dashboardSaveGameReport = function (options) {

    var $viewings = $('#game-report-viewings');
    var $gameInstances = $('#game-instances');
    var gameInstanceHtml = $gameInstances.html();

    var rebuildSelect2 = function () {
        $('.game-report-hometeamid:not(.select2-hidden-accessible), .game-report-visitingteamid:not(.select2-hidden-accessible)').each(function (idx, select) {
            $(select).select2();
        });
    }

    new Cleave('.game-report-gamedate', {
        date: true,
        datePattern: ['m', 'd', 'Y']
    });

    rebuildSelect2();

    $viewings.on('change', function (e) {
        var numViewings = parseInt($(this).val());
        var lastNumViewings = $gameInstances.children().length;
        var firstChild = $gameInstances.children().first();

        if (numViewings > lastNumViewings) {
            var toAdd = numViewings - lastNumViewings;
            for (var i = 0; i < toAdd; i++) {
                $gameInstances.append(gameInstanceHtml);
            }

            $('.game-report-gamedate').each(function (idx, input) {
                new Cleave(input, {
                    date: true,
                    datePattern: ['m', 'd', 'Y']
                });
            });
            rebuildSelect2();
        }
        else {
            var toRemove = lastNumViewings - numViewings;
            for (var i = 0; i < toRemove; i++) {
                $gameInstances.children().last().remove();
            }
        }
    });

    var fields = [{
        name: 'playerid',
        $input: $('#game-report-playerid')
    }, {
        name: 'submitterid',
        $input: $('#game-report-author')
    }, {
        name: 'isgoalie',
        $input: $('#game-report-isgoalie')
    }, {
        name: 'tournamentid',
        $input: $('#game-report-tournament')
    }, {
        name: 'viewings',
        $input: $('#game-report-viewings')
    }, {
        name: 'playernumber',
        $input: $('#game-report-playernumber')
    }, {
        name: 'projection',
        $input: $('#game-report-projection')
    }, {
        name: 'playertype',
        $input: $('#game-report-playertype')
    }, {
        name: 'gamerating',
        $input: $('#game-report-gamerating')
    }, {
        name: 'overallrating',
        $input: $('#game-report-overallrating')
    }, {
        name: 'compete',
        $input: $('#game-report-compete')
    }, {
        name: 'positioning',
        $input: $('#game-report-positioning')
    }, {
        name: 'reboundcontrol',
        $input: $('#game-report-reboundcontrol')
    }, {
        name: 'glovehand',
        $input: $('#game-report-glovehand')
    }, {
        name: 'useofstick',
        $input: $('#game-report-useofstick')
    }, {
        name: 'reflexes',
        $input: $('#game-report-reflexes')
    }, {
        name: 'senseanticipation',
        $input: $('#game-report-senseanticipation')
    }, {
        name: 'skating',
        $input: $('#game-report-skating')
    }, {
        name: 'quickness',
        $input: $('#game-report-quickness')
    }, {
        name: 'strength',
        $input: $('#game-report-strength')
    }, {
        name: 'shot',
        $input: $('#game-report-shot')
    }, {
        name: 'puckskills',
        $input: $('#game-report-puckskills')
    }, {
        name: 'offensiveplay',
        $input: $('#game-report-offensiveplay')
    }, {
        name: 'defensiveplay',
        $input: $('#game-report-defensiveplay')
    }, {
        name: 'hockeysense',
        $input: $('#game-report-hockeysense')
    }, {
        name: 'summary',
        $input: $('#game-report-summary')
    }];

    var dynamicFields = [{
        name: 'gamedate',
        className: '.game-report-gamedate'
    }, {
        name: 'hometeamid',
        className: '.game-report-hometeamid'
    }, {
        name: 'hometeamscore',
        className: '.game-report-hometeamscore'
    }, {
        name: 'visitingteamid',
        className: '.game-report-visitingteamid'
    }, {
        name: 'visitingteamscore',
        className: '.game-report-visitingteamscore'
    }];

    var formSave = new Form({
        endpoint: '/Dashboard/SaveGameReport/' + $('#dashboard').attr('data-id'),
        type: 'form',
        fields: fields,
        dynamicFields: dynamicFields,
        $btn: $('#game-report-save'),
        $errors: $('#game-report-errors'),
        onSuccess: function (response) {
            IHI.redirect('/Dashboard/GameReports/' + response.id);
        }
    });

    var formSubmit = new Form({
        endpoint: '/Dashboard/SubmitGameReport/' + $('#dashboard').attr('data-id'),
        type: 'form',
        fields: fields,
        dynamicFields: dynamicFields,
        $btn: $('#game-report-submit'),
        $errors: $('#game-report-errors'),
        onSuccess: function (response) {
            IHI.redirect('/Dashboard/GameReports/' + response.id);
        }
    });

}