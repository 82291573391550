IHI.Pages.dashboardViewGameReport = function (options) {
    
    $('#game-report-approve').on('click', function (e) {
        var gameReportId = $('#dashboard').attr('data-id');

        Popup.showYesNo('This will approve the application and send report notifications to anyone following the player including parents and scouts.  Would you like to proceed?', function () {
            $.getJSON('/Dashboard/ApproveGameReport/' + gameReportId, function (response) {
                if (response.success) {
                    Popup.showMessage('The game report has been approved.', function () {
                        IHI.redirect('/Dashboard/GameReports/' + gameReportId);
                    });
                }
                else {
                    Popup.showError(response.message);
                }
            });
        });
    });

}