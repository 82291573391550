IHI.Pages.dashboardDataMigration = function (options) {

    $(document).ready(function () {
        $('#apply').click(function () {
            var $elem = $("input:radio:checked");
            var newPlayerId = $elem.val();
            var oldPlayerId = $elem.siblings("input:radio").val();
            $.post('/Dashboard/MigratePlayer', {
                newPlayerId: newPlayerId,
                oldPlayerId: oldPlayerId
            }, function(response) {
                if (!response.success) {
                    alert("Unable to migrate player data.");
                }
                else {
                    //IHI.redirect('/Dashboard/DataMigration');
                }
            }, 'json');
        });
    });



    /*var $apply = $('#apply');
     $apply.on('click', function (e) {
         alert("hello world");
         $("tr.players").each(function (i) {
             var $link = this;
             update($link);
         });
     });
 
     var update = function ($row) {
         var Id = $row.closest("input:radio:checked").val();
         $.getJSON('/Dashboard/MigratePlayer/' + Id, function (response) {
             if (response.success) {
                 alert("something worked");
             }
             else {
                 alert("something didnt work");
             }
         });
     }*/
}
