var Form = function (options) {

    var fields = options.fields;
    var dynamicFields = options.dynamicFields;
    var $btn = options.$btn;
    var $errors = options.$errors;
    var postType = typeof options.type === 'undefined' ? 'json' : options.type;

    $btn.on('click', function (e) {
        e.preventDefault();
        submit();
    });

    // prevent native form submit on enter
    $btn.closest('form').on('submit', function (e) {
        e.preventDefault();
        submit();
    });
    
    var submit = function () {
        if (locked())
            return;

        lock();

        var headers = {};
        if (options.csrf === true)
            headers['RequestVerificationToken'] = $('input[name=__RequestVerificationToken]').val();

        var data = {};
        fields.forEach(function (f) {
            var val = f.$input.val();
            if (Array.isArray(val)) {
                if (val.length > 0) {
                    data[f.name] = val.join(',');
                } else {
                    data[f.name] = '';
                }
            } else {
                data[f.name] = val;
            }            
        });

        if (typeof dynamicFields !== 'undefined') {
            dynamicFields.forEach(function (f) {
                var fields = [];
                $(f.className).each(function (idx, input) {
                    fields.push($(input).val());
                });
                data[f.name] = fields;
            });
        }

        var ajaxSettings = {
            type: 'POST',
            headers: headers,
            dataType: 'json',
            complete: function (xhr) {
                unlock();

                var response = xhr.responseJSON;

                if (response.success) {
                    if (typeof options.onSuccess === 'function')
                        options.onSuccess(response);
                }
                else {
                    onError(response.message);

                    if (typeof options.onFailure === 'function')
                        options.onFailure(response);
                }
            }
        };

        if (postType === 'form') {
            ajaxSettings.data = data;
        }
        else {
            ajaxSettings.contentType = 'application/json';
            ajaxSettings.data = JSON.stringify(data);
        }

        $.ajax(options.endpoint, ajaxSettings);
    }

    var onError = function (message) {
        $errors.html(message).show();
    }

    var lock = function () {
        $btn.attr('disabled', 'disabled');
        $errors.empty().hide();
    }

    var locked = function () {
        return $btn.attr('disabled') === 'disabled';
    }

    var unlock = function () {
        $btn[0].removeAttribute('disabled');
    }

}