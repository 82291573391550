IHI.Pages.dashboardMessages = function (options) {

    $('#filter-status').on('change', function (e) {
        var val = $(this).val();
        if (val === '')
            IHI.redirect(document.location.pathname);
        else
            IHI.redirect(document.location.pathname + '?playerId=' + val);
    });

    $('#messages-new').on('click', function (e) {
        e.preventDefault();
        IHI.redirect('/Dashboard/NewMessage');
    });

    $('.messages-view-notes').on('click', function (e) {
        e.preventDefault();
        Popup.showMessage($(this).parent().next().html());
    });

    var form = new Form({
        endpoint: '/Dashboard/NewMessage',
        fields: [{
            name: 'subjectid',
            $input: $('#new-message-subject')
        }, {
            name: 'playerid',
            $input: $('#new-message-player')
        }, {
            name: 'gamedate',
            $input: $('#new-message-gamedate')
        }, {
            name: 'gametime',
            $input: $('#new-message-gametime')
        }, {
            name: 'gamelocation',
            $input: $('#new-message-gamelocation')
        }, {
            name: 'gameopponent',
            $input: $('#new-message-gameopponent')
        }, {
            name: 'notes',
            $input: $('#new-message-notes')
        }],
        $btn: $('#new-message-submit'),
        $errors: $('#new-message-errors'),
        onSuccess: function (response) {
            Popup.showMessage(response.message, function () {
                if (response.success)
                    IHI.redirect('/Dashboard/Messages');
            });
        }
    });
}