var Auth = (function () {

    var user = null;

    return {
        set: function (userObj) {
            if (typeof userObj !== 'object')
                return;

            if (typeof userObj.email !== 'undefined')
                user = userObj;
        },

        setToken: function (token) {
            Storage.userToken.set(token, 7);
        },

        loggedIn: function () {
            return user !== null;
        }
    };

})();