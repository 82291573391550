IHI.Pages.contact = function (options) {

    var form = new Form({
        endpoint: '/Contact/Submit',
        csrf: true,
        fields: [{
            name: 'name',
            $input: $('#contact-name')
        }, {
            name: 'email',
            $input: $('#contact-email')
        }, {
            name: 'message',
            $input: $('#contact-message')
        }],
        $btn: $('#contact-submit'),
        $errors: $('#contact-errors'),
        onSuccess: function () {
            IHI.redirect('/Contact?done=1');
        }
    });
    
}