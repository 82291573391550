IHI.Pages.dashboardAddCommitment = function (options) {

    var $team = $('#add-commitment-team');
    $team.select2();

    var form = new Form({
        endpoint: '/Dashboard/AddCommitment',
        fields: [{
            name: 'playerid',
            $input: $('#add-commitment-playerid')
        }, {
            name: 'teamid',
            $input: $team
        }],
        $btn: $('#add-commitment-submit'),
        $errors: $('#add-commitment-errors'),
        onSuccess: function (response) {
            Popup.showMessage(response.message, function () {
                IHI.redirect('/Dashboard/Players');
            });
        }
    });

}