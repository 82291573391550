IHI.Pages.plans = function () {

    var $planButtons = $('#plans button');

    // button events
    $planButtons.on('click', function (e) {
        e.preventDefault();
        IHI.redirect('/Application');
    });

}