IHI.Pages.forgotpassword = function (options) {

    var form = new Form({
        endpoint: '/ForgotPassword',
        csrf: true,
        fields: [{
            name: 'email',
            $input: $('#forgot-pass-email')
        }],
        $btn: $('#forgot-pass-submit'),
        $errors: $('#forgot-pass-errors'),
        onSuccess: function () {
            Popup.showMessage('A notification has been sent to the email address provided with a link to reset your password.  Please allow at least five minutes for it to arrive.');
        }
    });
    
}