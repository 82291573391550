IHI.Pages.dashboardApplicationChangePlan = function (options) {

    var form = new Form({
        endpoint: '/Dashboard/ApplicationChangePlan',
        fields: [{
            name: 'id',
            $input: $('#app-change-id')
        }, {
            name: 'plan',
            $input: $('#app-change-plan')
        }, {
            name: 'categories',
            $input: $('#app-change-categories')
        }],
        $btn: $('#app-change-submit'),
        $errors: $('#app-change-errors'),
        onSuccess: function () {
            IHI.redirect('/Dashboard/Applications');
        }
    });

}