IHI.Pages.dashboardApplications = function (options) {

    $('#filter-status').on('change', function (e) {
        IHI.redirect(document.location.pathname + '?status=' + $(this).val());
    });

    $('.menu-approve').on('click', function (e) {
        var $link = $(this);
        var name = $link.closest('tr').children('td').first().text();
        Popup.showConfirm('The applicant will receive an email notification that they have been approved.  Are you sure you want to continue?', 'Approve ' + name, function () {
            approve($link);
        });
    });

    $('.menu-remove').on('click', function (e) {
        var $link = $(this);
        var name = $link.closest('tr').children('td').first().text();
        Popup.showConfirm('The applicant will receive an email notification that they have been denied.  A refund will also be issued.  Are you sure you want to continue?', 'Deny ' + name, function () {
            deny($link);
        });
    });

    var approve = function ($menuItem) {
        var $row = $menuItem.closest('tr');
        $.getJSON('/Dashboard/ApplicationApprove/' + $row.attr('data-id'), function (response) {
            if (response.success) {
                $row.fadeOut(function () {
                    $row.remove();
                });
            }
            else {
                Popup.showError('There was a problem approving the application or sending the email.');
            }
        });
    }

    var deny = function ($menuItem) {
        var $row = $menuItem.closest('tr');
        $.getJSON('/Dashboard/ApplicationDeny/' + $row.attr('data-id'), function (response) {
            if (response.success) {
                $row.fadeOut(function () {
                    $row.remove();
                });
            }
            else {
                Popup.showError('There was a problem denying the application or sending the email.');
            }
        });
    }
}