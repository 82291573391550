IHI.Pages.dashboardScouts = function (options) {

    $('#filter-status').on('change', function (e) {
        IHI.redirect(document.location.pathname + '?status=' + $(this).val());
    });

    $('.menu-approve').on('click', function (e) {
        var $link = $(this);
        var name = $link.closest('tr').children('td').first().text();
        Popup.showConfirm('The scout will receive an email notification that they have been approved.  Are you sure you want to continue?', 'Approve ' + name, function () {
            approve($link);
        });
    });

    var approve = function ($menuItem) {
        var $row = $menuItem.closest('tr');
        $.getJSON('/Dashboard/ApproveScout/' + $row.attr('data-id'), function (response) {
            if (response.success) {
                $row.fadeOut(function () {
                    $row.remove();
                    Popup.show(response.message);
                });
            }
            else {
                Popup.showError(response.message);
            }
        });
    };

    $('.menu-remove').on('click', function (e) {
        var $link = $(this);
        var name = $link.closest('tr').children('td').first().text();
        Popup.showConfirm('The scout will be removed and will be unable to login.  Are you sure you want to continue?', 'Remove ' + name, function () {
            remove($link);
        });
    });

    var remove = function ($menuItem) {
        var $row = $menuItem.closest('tr');
        $.getJSON('/Dashboard/RemoveScout/' + $row.attr('data-id'), function (response) {
            if (response.success) {
                $row.fadeOut(function () {
                    $row.remove();
                    Popup.show(response.message);
                });
            }
            else {
                Popup.showError(response.message);
            }
        });
    };
}