IHI.Pages.dashboardGameReports = function (options) {

    $('#filter-status').on('change', function (e) {
        IHI.redirect(document.location.pathname + '?status=' + $(this).val());
    });

    $('.menu-remove').on('click', function (e) {
        var $link = $(this);
        Popup.showConfirm('Are you sure you want to remove this game report?', 'Remove Game Report', function () {
            remove($link);
        });
    });

    var remove = function ($menuItem) {
        var $row = $menuItem.closest('tr');
        $.getJSON('/Dashboard/RemoveGameReport/' + $row.attr('data-id'), function (response) {
            if (response.success) {
                $row.fadeOut(function () {
                    $row.remove();
                });
            }
            else {
                Popup.showError('There was a problem removing the game report.');
            }
        });
    }

}