Popup.spinner = function (message) {
    const cacheId = 'spinner-template';

    var hasSpinner = document.querySelector('.popup-spinner') !== null;
    var hasMessage = typeof message === 'string';

    // shows the message under the spinner
    var showMessage = function () {
        var pMsg = document.getElementById('spinner-message');
        pMsg.innerText = message;
        $(pMsg).addClass('on');
    }

    if (hasSpinner && hasMessage) {
        showMessage();
        return;
    }
    else if (hasSpinner)
        return;

    Popup.spinnerDelay = setTimeout(function () {
        Popup.spinnerDelay = null;

        Popup.show(Popup.getCached(cacheId), {
            closeOnOverlay: false,
            closeOnX: false,
            showX: false,
            padding: false
        }, {
            beforeShow: function () {
                var popups = document.querySelectorAll('.popup-container');
                $(popups[popups.length - 1]).addClass('popup-spinner');

                if (hasMessage)
                    showMessage();
            }
        });
    }, 150);
}

Popup.showConfirm = function (message, title, onOk, onCancel) {
    this.show(message, { title: title }, {
        onOk: function () {
            if (typeof onOk === 'function')
                onOk();
            Popup.hide();
        },
        onCancel: function () {
            if (typeof onCancel === 'function')
                onCancel();
            Popup.hide();
        },
        onX: function () {
            if (typeof onCancel === 'function')
                onCancel();
        }
    });
}

Popup.showYesNo = function (message, onYes, onNo) {
    this.show(message, {}, {
        onYes: function () {
            if (typeof onYes === 'function')
                onYes();
            Popup.hide();
        },
        onNo: function () {
            if (typeof onNo === 'function')
                onNo();
            Popup.hide();
        },
        onX: function () {
            if (typeof onNo === 'function')
                onNo();
        }
    });
}

Popup.showMessage = function (content, callback) {
    this.show(content, {}, {
        onOk: function () {
            Popup.hide();

            if (typeof callback === 'function')
                callback();
        },
        afterHide: function () {
            if (typeof callback === 'function')
                callback();
        }
    });
}

Popup.showError = function (content) {
    this.show(content, { title: '<i class="fa fa-exclamation-triangle"></i> Error' }, {
        onOk: function () {
            Popup.hide();
        }
    });
}

Popup.showAddCommitment = function (playerId, onBeforeShow) {
    $.get({
        url: '/Dashboard/AddCommitment/' + playerId,
        dataType: 'html',
        success: function (response) {
            Popup.show(response, {}, {
                beforeShow: onBeforeShow
            });
        }
    });
}

Popup.showEvent = function (eventId, onBeforeShow) {
    $.get({
        url: '/Dashboard/EditEvent/' + eventId,
        dataType: 'html',
        success: function (response) {
            Popup.show(response, {}, {
                beforeShow: onBeforeShow
            });
        }
    });
}

Popup.showTeam = function (teamId, onBeforeShow) {
    $.get({
        url: '/Dashboard/EditTeam/' + teamId,
        dataType: 'html',
        success: function (response) {
            Popup.show(response, {}, {
                beforeShow: onBeforeShow
            });
        }
    });
}