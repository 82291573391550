IHI.Pages.dashboardEvents = function (options) {

    var $addEvent = $('#add-event');
    var $calendar = $('.calendar tbody');
    var $monthYearLabel = $('h2 span');
    var $calendarBack = $('#calendar-back');
    var $calendarForward = $('#calendar-forward');
    var addEventForm = null;
    var now = new Date(Date.parse(options.date));

    var buildCalendar = function () {
        $calendar.html('');

        //$monthYearLabel.text(Chronos.getMonthName(now.getMonth()) + ' ' + now.getFullYear());

        var weeks = Chronos.getWeeks(now);
        weeks.forEach(function (week) {
            buildWeek(week);
        });

        $calendar.find('a').on('click', function (e) {
            e.preventDefault();
            Popup.showEvent($(this).attr('data-id'), preShowEvent);
        });
    };

    var buildWeek = function (week) {
        var html = '<tr>';

        week.forEach(function (day) {
            var tdClass = 'date';
            if (Chronos.dateIsToday(day)) {
                tdClass += ' today';
            }

            html += '<td><span class="' + tdClass + '">' + day.getDate() + '</span>';
            html += getEventsHtmlByDate(day);
            html += '</td>';
        });

        html += '</tr>';

        $calendar.append(html);
    };

    var getEventsHtmlByDate = function (day) {
        var html = '';

        var todaysEvents = options.events.filter(function (e) {
            var eStart = new Date(e.StartTime);
            return day.getFullYear() === eStart.getFullYear() && day.getMonth() === eStart.getMonth() && day.getDate() === eStart.getDate();
        });

        todaysEvents.forEach(function (e) {
            html += '<a href="javascript: void(0)" data-id="' + e.Id + '">' + e.Name + '</a><br />';
        });

        return html;
    };

    var preShowEvent = function (e) {
        var isNewEvent = $('#edit-event-id').val() === '0';

        addEventForm = new Form({
            endpoint: '/Dashboard/SaveEvent',
            fields: [{
                name: 'id',
                $input: $('#edit-event-id')
            }, {
                name: 'creatorId',
                $input: $('#edit-event-creator-id')
            }, {
                name: 'name',
                $input: $('#edit-event-name')
            }, {
                name: 'startDate',
                $input: $('#edit-event-start')
            }, {
                name: 'startTime',
                $input: $('#edit-event-start-time')
            }, {
                name: 'endDate',
                $input: $('#edit-event-end')
            }, {
                name: 'endTime',
                $input: $('#edit-event-end-time')
            }, {
                name: 'description',
                $input: $('#edit-event-description')
            }, {
                name: 'attendingStaff',
                $input: $('#edit-event-staff-list')
            }],
            $btn: $('#edit-event-submit'),
            $errors: $('#edit-event-errors'),
            onSuccess: function (response) {
                window.location.reload();
                return;
            },
            onError: function (response) {
                Popup.showError(response);
            }
        });

        if (!isNewEvent) {
            $('.attendees').show();
        }

        autoComplete($('#edit-event-players'), 'player');
        autoComplete($('#edit-event-staff'), 'staff');

        $('#edit-event-start-time, #edit-event-end-time').each(function (idx, select) {
            var $select = $(select);
            $select[0].selectedIndex = $select.children('[value="' + $select.attr('data-time') + '"]').index();
        });
    };

    var autoComplete = function ($input, type) {
        $input.on('keyup', function () {
            var $container = $input.next('.autocomplete');
            var value = $(this).val();
            if (value.length >= 2) {
                if ($container.length === 0) {
                    $container = $('<div class="autocomplete"></div>');
                    $container.on('click', '.entry', function () {
                        var $entry = $(this);
                        var id = $entry.attr('data-id');
                        var value = $entry.text();
                        var $list = type === 'player' ?
                                    $('#edit-event-player-list') :
                                    $('#edit-event-staff-list');

                        if ($list.text().indexOf(value) > -1) {
                            Popup.showError(value + ' is already attending.');
                            return;
                        }

                        addAttendee(id, value, type, $list);
                    });
                    $container.insertAfter($input);                    
                }

                invokeAutocomplete(value, type);
            }
            else {
                $container.remove();
            }
        });
    };

    var invokeAutocomplete = function (text, type) {
        $.ajax('/Dashboard/EventAttendeeAutocomplete', {
            type: 'POST',
            dataType: 'json',
            contentType: 'application/json',
            data: JSON.stringify({
                text: text,
                type: type
            }),
            complete: function (xhr) {
                var response = xhr.responseJSON;

                if (response.success) {
                    populateAutocomplete(response.options);
                }
                else {
                    console.log('Failed autocomplete fetch.');
                }
            }
        });
    };

    var populateAutocomplete = function (options) {
        var $container = $('.autocomplete');
        $container.html('');

        options.forEach(function (opt) {
            var $opt = $('<div class="entry" data-id="' + opt.Value + '">' + opt.Label + '</div>');
            $container.append($opt);
        });
    };

    var addAttendee = function (id, value, type, $list) {
        $.ajax('/Dashboard/AddEventAttendee', {
            type: 'POST',
            dataType: 'json',
            contentType: 'application/json',
            data: JSON.stringify({
                id: id,
                eventId: $('#edit-event-id').val(),
                type: type
            }),
            complete: function (xhr) {
                var response = xhr.responseJSON;

                if (response.success) {
                    var prepend = $list.val() === '' ? '' : ', ';
                    $list.val($list.val() + prepend + value);
                    $list.prev().val('');

                    $('.autocomplete').remove();
                }
                else {
                    Popup.showError('There was a problem adding this person to the event.');
                }
            }
        });
    };

    //var calendarShift = function (dir) {
    //    now = Chronos.addMonths(now, dir);
    //    buildCalendar();
    //};

    // Init

    $addEvent.on('click', function (e) {
        e.preventDefault();
        Popup.showEvent(0, preShowEvent);
    });

    $('.attend-event').on('click', function (e) {
        e.preventDefault();

        var $btn = $(this),
            $dayCard = $btn.closest('.day-card'),
            $staff = $dayCard.children('.card-info').children('p:last'),
            eventId = $btn.attr('data-id'),
            skipCallback = false;

        $.ajax('/Dashboard/AddEventAttendee', {
            type: 'POST',
            dataType: 'json',
            contentType: 'application/json',
            data: JSON.stringify({
                id: 0, // will be pulled from SessionUser
                eventId: eventId,
                type: 'generic'
            }),
            complete: function (xhr) {
                var response = xhr.responseJSON;

                if (response.success) {
                    if (response.name === '') {
                        $btn.attr('disabled', 'disabled');
                        $btn.text('Invite Requested');
                    }
                    else {
                        $btn.remove();
                    }
                    Popup.showMessage(response.message, function () {
                        if (skipCallback || response.name === '')
                            return;
                        skipCallback = true;

                        var text = $staff.text();
                        if (text === 'None')
                            $staff.text(response.name);
                        else
                            $staff.text(text + response.name);
                    });
                }
                else {
                    Popup.showError('There was a problem adding this person to the event.');
                }
            }
        });
    });

    $('.approve-staff').on('click', function (e) {
        e.preventDefault();

        var $btn = $(this),
            eventId = $btn.attr('data-event-id'),
            userId = $btn.attr('data-id');

        $.ajax('/Dashboard/ApproveEventAttendee', {
            type: 'POST',
            dataType: 'json',
            contentType: 'application/json',
            data: JSON.stringify({
                userId: userId,
                eventId: eventId
            }),
            complete: function (xhr) {
                var response = xhr.responseJSON;

                if (response.success) {
                    $btn.remove();
                    Popup.showMessage(response.message);
                }
                else {
                    Popup.showError(response.message);
                }
            }
        });
    });

    $('.remove-player').on('click', function (e) {
        e.preventDefault();

        var $btn = $(this),
            eventId = $btn.attr('data-event-id'),
            playerId = $btn.attr('data-id');

        $.ajax('/Dashboard/RemovePlayerEventAttendee', {
            type: 'POST',
            dataType: 'json',
            contentType: 'application/json',
            data: JSON.stringify({
                playerId: playerId,
                eventId: eventId
            }),
            complete: function (xhr) {
                var response = xhr.responseJSON;

                if (response.success) {
                    $btn.parent().remove();
                    Popup.showMessage(response.message);
                }
                else {
                    Popup.showError(response.message);
                }
            }
        });
    });

    $('.remove-staff').on('click', function (e) {
        e.preventDefault();

        var $btn = $(this),
            eventId = $btn.attr('data-event-id'),
            userId = $btn.attr('data-id');

        $.ajax('/Dashboard/RemoveUserEventAttendee', {
            type: 'POST',
            dataType: 'json',
            contentType: 'application/json',
            data: JSON.stringify({
                userId: userId,
                eventId: eventId
            }),
            complete: function (xhr) {
                var response = xhr.responseJSON;

                if (response.success) {
                    $btn.parent().remove();
                    Popup.showMessage(response.message);
                }
                else {
                    Popup.showError(response.message);
                }
            }
        });
    });

    $('.edit-event').on('click', function (e) {
        e.preventDefault();
        Popup.showEvent($(this).attr('data-id'), preShowEvent);
    });

    $('.delete-event').on('click', function (e) {
        e.preventDefault();

        var $btn = $(this),
            $dayCard = $btn.closest('.day-card'),
            eventId = $btn.attr('data-id');

        $.ajax('/Dashboard/DeleteEvent', {
            type: 'POST',
            dataType: 'json',
            contentType: 'application/json',
            data: JSON.stringify({
                id: eventId
            }),
            complete: function (xhr) {
                var response = xhr.responseJSON;

                if (response.success) {
                    $dayCard.remove();
                    Popup.showMessage(response.message);
                }
                else {
                    Popup.showError(response.message);
                }
            }
        });
    });

    $calendarBack.on('click', function (e) {
        e.preventDefault();
        document.location.href = $(this).attr('data-href');
        //calendarShift(-1);
    });

    $calendarForward.on('click', function (e) {
        e.preventDefault();
        document.location.href = $(this).attr('data-href');
        //calendarShift(1);
    });

    $('.approve-event').on('click', function (e) {
        e.preventDefault();

        var $btn = $(this),
            $dayCard = $btn.closest('.day-card'),
            eventId = $btn.attr('data-id');

        $.getJSON('/Dashboard/ApproveEvent/' + eventId, function (response) {
            if (response.success) {
                $dayCard.removeClass('unapproved');
                $btn.remove();
            }
            else {
                Popup.showError(response.message);
            }
        });
    });

    $(document.body).on('click', function (e) {
        var $target = $(e.target);
        if (!$target.is('.autocomplete') && $target.closest('.autocomplete').length === 0) {
            $('.autocomplete').remove();
        }
    });
    
    buildCalendar();
}