IHI.Pages.dashboardPlayers = function (options) {
    
    $('.follow i').on('click', function (e) {
        var $i = $(this);
        var playerId = $i.closest('tr').attr('data-id');

        if ($i.hasClass('fa-square')) {
            follow($i, playerId);
        }
        else {
            unfollow($i, playerId);
        }
    });

    var follow = function ($i, pId) {
        $i.removeClass('fa-square');
        $i.addClass('fa-check-square');

        $.getJSON('/Dashboard/FollowPlayer/' + pId, function (response) {
            if (response.success !== true) {
                $i.removeClass('fa-check-square');
                $i.addClass('fa-square');

                Popup.showError(response.message);
            }
        });
    };

    var unfollow = function ($i, pId) {
        $i.removeClass('fa-check-square');
        $i.addClass('fa-square');

        $.getJSON('/Dashboard/UnfollowPlayer/' + pId, function (response) {
            if (response.success !== true) {
                $i.removeClass('fa-square');
                $i.addClass('fa-check-square');

                Popup.showError(response.message);
            }
        });
    };

}