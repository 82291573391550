/**
 * Main IHI object
 */

var IHI = {

    init: function (options) {

        Auth.set(options.user);

        if (typeof options.controller === 'function')
            options.controller(options);
        else
            console.error('Invalid controller.');
    },

    redirect: function (url) {
        window.location.href = url;
    },
	
    Pages: {}

};
