IHI.Pages.dashboardAddStaff = function (options) {

    var form = new Form({
        endpoint: '/Dashboard/AddStaff',
        fields: [{
            name: 'firstname',
            $input: $('#add-staff-firstname')
        }, {
            name: 'lastname',
            $input: $('#add-staff-lastname')
        }, {
            name: 'email',
            $input: $('#add-staff-email')
        }],
        $btn: $('#add-staff-submit'),
        $errors: $('#add-staff-errors'),
        onSuccess: function () {
            IHI.redirect('/Dashboard/Staff');
        }
    });

}